<template>
  <router-view />
</template>

<script>
import '@/styles/overrides.sass'
import '@/styles/project.css'

export default {
  name: 'App',
  metaInfo: {
    title: 'FET CAT3',
    titleTemplate: '%s | IOT Platform ans Solution',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
}
</script>
