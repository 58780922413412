// Pathify
import { make } from 'vuex-pathify'

// state - 類似 Vue 組件中 的 data
const state = {
  message: '預設的內容',
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Solution',
      icon: 'mdi-home',
      to: '/all-projects/'
    },
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      items: [
        {
          title: 'Dashboard1',
          icon: 'mdi-view-dashboard',
          to: '/dashboard/dashboard1/'
        },
        {
          title: 'Dashboard2',
          icon: 'mdi-view-dashboard',
          to: '/dashboard/dashboard2/'
        },
        {
          title: 'Dashboard3',
          icon: 'mdi-view-dashboard',
          to: '/dashboard/dashboard3/'
        }
      ]
    },
    {
      title: 'Map',
      icon: 'mdi-post',
      items: [
        {
          title: 'OpenLayer',
          icon: 'mdi-map',
          to: '/map/openlayer/'
        },
        {
          title: 'Echarts Map',
          icon: 'mdi-map',
          to: '/map/echarts/'
        },
        {
          title: 'Leaflet',
          icon: 'mdi-map',
          to: '/map/leaflet/'
        },
        {
          title: 'ArcGis',
          icon: 'mdi-map',
          to: '/map/arcgis/'
        },
        {
          title: 'Google Map',
          icon: 'mdi-map-marker',
          to: '/map/google/'
        }
      ]
    },
    {
      title: 'Component',
      icon: 'mdi-view-module',
      items: [
        {
          title: 'Switch',
          icon: 'mdi-toggle-switch',
          to: '/component/switch/'
        },
        {
          title: 'Alert',
          icon: 'mdi-comment-alert',
          to: '/component/alert/'
        },
        {
          title: 'Status',
          icon: 'mdi-text-box-check',
          to: '/component/status/'
        },
        {
          title: 'Data Transition',
          icon: 'mdi-signal-variant',
          to: '/component/data-transition/'
        },
        {
          title: 'Log file',
          icon: 'mdi-post',
          to: '/component/log-file/'
        },
        {
          title: 'Notification',
          icon: 'mdi-bell-badge',
          to: '/component/notification/'
        },
        {
          title: 'Icon',
          icon: 'mdi-diamond-stone',
          to: '/component/icon/'
        },
        {
          title: 'Typography',
          icon: 'mdi-format-text-variant-outline',
          to: '/component/typography/'
        },
        {
          title: 'Table',
          icon: 'mdi-table',
          to: '/component/table/'
        }
      ]
    },
    {
      title: 'Page',
      icon: 'mdi-page-next',
      items: [
        {
          title: 'Login',
          icon: 'mdi-login',
          to: '/page/login/'
        },
        {
          title: 'User Profile',
          icon: 'mdi-face-man-profile',
          to: '/page/profile/'
        },
        {
          title: 'Error',
          icon: 'mdi-alert-circle',
          to: '/page/error/'
        },
        {
          title: 'Page...',
          icon: 'mdi-page-next',
          to: '/page/page.../'
        }
      ]
    }
  ]
}

// 可視為 Vue 組件中的 computed
const getters = {
}

// action - 用來呼叫 mutations 的入口
const actions = {
  // 「...」為「展開運算子」
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  }
}

// mutations - 類似 Vue 組件中的 methods
const mutations = make.mutations(state)

export default {
  // namespaced 會在 Module 的 action、mutation、getter 加上 prefix
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
