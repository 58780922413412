<template>
  <material-card
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #title>
      <div class="v-card__title--material-stats" v-text="value" />
    </template>

    <!-- <template #actions>
      <slot name="actions" />
    </template> -->

    <!-- <slot /> -->
  </material-card>
</template>

<script>
export default {
  name: 'MaterialStatCardSimple',
  props: { value: String }
}
</script>

<style scpoed lang="sass">
.v-card--material-stats.v-card--material .v-card--material__title
  color: #999999

.v-card__title--material-stats
  color: #3C4858
  margin-left: auto
  font-size: 1.2rem !important
  font-weight: 700
  margin-bottom: 5px
</style>
