import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import { gsap } from 'gsap'
import axios from 'axios'
import VueAxios from 'vue-axios'
import echarts from 'echarts'
import 'leaflet/dist/leaflet.css'

Vue.prototype.$gsap = gsap
Vue.use(VueAxios, axios)
Vue.prototype.$echarts = echarts

// axios.defaults.timeout = 5000 // 请求超时

// add for 開發環境
// axios.defaults.baseURL = 'Api'

// add for 生產環境
// axios.defaults.baseURL = 'https://huangling-event-trigger-others.azurewebsites.net'

// /* 设置baseURL */
// let baseURL = ''
// // process.env.NODE_ENV  用于判断当前运行环境，根据 npm run serve 或 npm run build
// if (process.env.NODE_ENV === 'production') {
//   baseURL = 'https://huangling-event-trigger-others.azurewebsites.net'
// }
// axios.defaults.baseURL = baseURL

Vue.config.productionTip = false

sync(store, router)

window.store = store
window.app = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

// debug
// console.log('computed:', App.computed)
console.log('mutations:', store._mutations)
