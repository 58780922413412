// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history', // 註解 for build
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [ // 連動於 AppBar.vue 中檢查
    layout('Default', [
      // index
      route('AllProjects', null, '/'),
      route('AllProjects', null, 'all-projects'),

      // Dashboard
      route('Dashboard1', null, 'dashboard/dashboard1'),
      route('Dashboard2', null, 'dashboard/dashboard2'),
      route('Dashboard3', null, 'dashboard/dashboard3'),

      // Maps
      route('MapEcharts', null, 'map/echarts'),
      route('MapOpenlayer', null, 'map/openlayer'),
      route('MapLeaflet', null, 'map/leaflet'),
      route('MapArcGIS', null, 'map/arcgis'),
      route('MapGoogle', null, 'map/google'),

      // Components
      route('ComponentSwitch', null, 'component/switch'),
      route('ComponentAlert', null, 'component/alert'),
      route('ComponentStatus', null, 'component/status'),
      route('ComponentDataTransition', null, 'component/data-transition'),
      route('ComponentLogFile', null, 'component/log-file'),
      route('ComponentNotification', null, 'component/notification'),
      route('ComponentIcon', null, 'component/icon'),
      route('ComponentTypography', null, 'component/typography'),
      route('ComponentTable', null, 'component/table'),

      // Pages
      route('PageLogin', null, 'page/login'),
      route('PageUserProfile', null, 'page/profile'),
      route('PageError', null, 'page/error'),
      route('PageMore', null, 'page/page...')
    ])
  ]
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
