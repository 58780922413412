// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, { directives: { ripple } })

// 註解最初的變數寫法
// const theme = {
//   primary: '#ff9800',
//   secondary: '#9C27b0',
//   accent: '#e91e63',
//   info: '#00CAE3',
//   success: '#4CAF50',
//   warning: '#FB8C00',
//   error: '#FF5252',
// }

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' }
    // Material Design Icons
    // iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: colors.red.darken1,
        secondary: colors.orange.darken2,
        accent: colors.yellow.darken2,
        info: colors.green.darken2,
        success: colors.blue.darken1,
        warning: colors.deepPurple.darken2,
        error: colors.red.accent3,
        // background: colors.grey.lighten5, // drawer-toggle bottom BG
        appBarBG: 'linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 35%, rgba(245,245,245,1) 100%)',
        labelBG: colors.grey.lighten1,
        toggleBG: 'white',
        bgtwo: colors.grey.lighten3,
        divider: colors.grey.lighten2,
        textwhite: colors.white
      },
      dark: {
        // primary: colors.blue.lighten3,
        // background: colors.purple.base, // If using base color, be use to mark as such to get HEX value
        primary: colors.red.darken1,
        // background: colors.grey.darken1,
        bgtwo: colors.grey.darken3,
        divider: colors.grey.darken4
      }
    }
  }
})
